import * as React from 'react'
import { graphql } from 'gatsby'
import { useRecoilValue } from 'recoil'
import { isMobileState } from '@/recoil/layout'

import Link from '@/components/LocalizedGatsbyLink'
import isExternalLink from '@/utils/isExternalLink'

import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import moment from 'moment'
import Layout from '@/layouts/Default'

import {
  Container,
  ImageWrapper,
  SwiperContainer,
  CircularButton,
  Image,
  Wrapper,
  Content,
  EventInfos,
  DateWrapper,
  DateContainer,
  DateItem,
  DateSeparator,
  Day,
  Month,
  DetailsWrapper,
  Name,
  Hours,
  References,
  Text,
  Button,
  Cta,
  EventOverText
} from '@/styles/pages/event/style.js'

const NewsPage = ({ data, pageContext: { requireAgeGate, pagePath, marketCode: pageMarketCode } }) => {
  const content = data.drupal.nodeById
  const isMobile = useRecoilValue(isMobileState)
  const location = useLocation()
  const to = content.fieldEventCtaBlock?.entity?.fieldCtaBlockCta?.url?.path
  const date = content?.fieldEventEndDate?.value ? content?.fieldEventEndDate?.value : content?.fieldEventStartDate?.value
  const onGoing = moment.utc(new Date()).isBefore(date)

  return (
    <Layout entityBundle='events' path={ pagePath } requireAgeGate={ requireAgeGate } plainHeader pageMarketCode={ pageMarketCode }>
      <Helmet>
        <title>{content.title}</title>
        <meta name='description' content={ content.fieldEventsMetaTags?.entity?.fieldMetaDescription } />
        <meta property='og:title' content={ content.fieldEventsMetaTags?.entity?.fieldMetaTitle } />
        <meta property='og:description' content={ content.fieldEventsMetaTags?.entity?.fieldMetaDescription } />
        <meta property='og:url' content={ `${process.env.URL || process.env.GATSBY_DRUPAL_URL}${pagePath}` } />
        <meta property='og:site_name' content='Cloudy Bay' />
        <meta property='og:type' content='article' />
        {content.fieldEventsMetaTags?.entity?.fieldMetaOpenGraphImage && <meta property='og:image' content={ content.fieldEventsMetaTags?.entity?.fieldMetaOpenGraphImage?.url } /> }
      </Helmet>
      <CircularButton
        id='textcercle'
        onClick={ () => {
          window.history.back()
        } }
        icon='arrow-left'
        label='back'
        color='white'
        nbLabels={ isMobile ? 7 : 9 }
      />
      <Container>
        <ImageWrapper>
          <SwiperContainer
            slidesPerView='auto'
          >
            {
              (content.fieldEventCover?.url) && (
                <Image loading='lazy' src={ content.fieldEventCover?.url } alt='event' />
              )
            }
          </SwiperContainer>
        </ImageWrapper>
        <Wrapper>
          <EventInfos>
            <DateWrapper>
              <DateContainer small={ !!data.drupal.nodeById?.fieldEventEndDate?.value }>
                <DateItem>
                  <Day>
                    { moment(data.drupal.nodeById?.fieldEventStartDate?.value).format('DD') }
                  </Day>
                  <Month>
                    {moment(data.drupal.nodeById?.fieldEventStartDate?.value).format('MMMM') }
                  </Month>
                </DateItem>
                {data.drupal.nodeById?.fieldEventEndDate?.value && (
                  <>
                    <DateSeparator />
                    <DateItem>
                      <Day>
                        { moment(data.drupal.nodeById?.fieldEventEndDate?.value).format('DD') }
                      </Day>
                      <Month>
                        {moment(data.drupal.nodeById?.fieldEventEndDate?.value).format('MMMM') }
                      </Month>
                    </DateItem>
                  </>
                )}
              </DateContainer>
            </DateWrapper>

            <DetailsWrapper>
              <Name>{ content.title }</Name>
              <Hours>{ content?.fieldEventHours }</Hours>
              <References>@{content.fieldEventLocation} - {content.fieldEventCountryLocation}</References>
            </DetailsWrapper>
          </EventInfos>
          <Content>
            <Text>
              { content?.fieldEventDescription }
            </Text>
            {!onGoing && (
              <EventOverText>This event is over.</EventOverText>
            )}
          </Content>
          {content?.fieldEventCtaBlock?.entity?.fieldCtaBlockCta?.title && onGoing &&
            <Button
              to={ to } target={ isExternalLink(to) ? '_blank' : '_self' }
            >
              { content?.fieldEventCtaBlock?.entity?.fieldCtaBlockCta?.title }
            </Button>}
        </Wrapper>
      </Container>
      {/* <ArticleWrapper> */}

      {/* <ArticleHero
          img={ isMobile ? content.fieldEventCoverMobile?.url : content.fieldEventCover?.url }
          ctaLabel='back'
          cta='/'
        />

        <ArticleTitle
          infos={ content?.fieldEventStartDate?.value && content?.fieldEventEndDate?.value ? `${startDate} - ${startHour} / ${endDate} - ${endHour}` : `${startDate} - ${startHour}` }
          event
          title={ content.title }
          introduction={ `@${content.fieldEventLocation} - ${content.fieldEventCountryLocation} ` }
          shareURL={ location.href }
          ctaLabel='back'
          cta='/'
        />
        <ArticleBlockText
          text1={ content.fieldEventDescription }
        />
        {content?.fieldEventCtaBlock && (<ArticleBlockCTA
          to={ content.fieldEventCtaBlock.entity.fieldCtaBlockCta.url.path }
          label={ content.fieldEventCtaBlock.entity.fieldCtaBlockCta.title }
          target='_blank'
                                         />)}
      </ArticleWrapper> */}
    </Layout>
  )
}

export default NewsPage

export const pageQuery = graphql`
query EventsQuery($nid: String!) {
  drupal {
    nodeById(id: $nid) {
      title
      ... on Drupal_NodeEvents {
        nid
        title
        fieldEventStartDate {
          value
          date
        }
        fieldEventEndDate {
          value
          date
        }
        fieldEventCountry {
          targetId
        }
        fieldEventCountryLocation
        fieldEventLocation
        fieldEventCover {
          height
          url
          width
          targetId
          alt
          title
        }
        fieldEventCoverMobile {
          height
          url
          width
          targetId
          alt
          title
        }
        fieldEventDescription
        fieldEventHours
        fieldEventCtaBlock {
          entity {
            ... on Drupal_ParagraphCtaBlock {
              id
              fieldCtaBlockCta {
                title
                url {
                  path
                }
              }
            }
          }
        }
        fieldEventsMetaTags {
          entity {
            ... on Drupal_ParagraphMetaTags {
              fieldMetaTitle
              fieldMetaDescription
              fieldMetaOpenGraphImage {
                url
              }
            }
          }
        }
      }
    }
  }
}
`
